import React from 'react';
import Email from '../../../components/Account/Email';
import PrivateRoute from '../../../components/PrivateRoute';
import ContextProvider from '../../../components/ContextProvider';

const EmailPageEnAu = () => (
  <ContextProvider localeCode="en-AU">
    <PrivateRoute path="/en-AU/account/email" component={Email} />
  </ContextProvider>
);

export default EmailPageEnAu;
