import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@rebass/forms/styled-components';
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  sendEmailVerification,
  updateEmail,
} from 'firebase/auth';
import { navigate } from 'gatsby';
import React, {
  ChangeEvent,
  createRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex } from 'rebass/styled-components';
import { fetchAndUpdateUser } from '../../utils/Auth';
import { AppleProviderId, firebaseAuth } from '../../utils/Firebase';
import { LocaleContext } from '../ContextProvider';
import { Layout } from '../Layout';
import {
  BackLink,
  ContentContainer,
  ErrorMessage,
  Label,
  Row,
  Title,
} from '../LayoutComponents';
import { AuthContext } from '../PrivateRoute';
import Section from '../Section';

enum UpdateEmailErrorCodes {
  INVALID_EMAIL = 'auth/invalid-email',
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
  WRONG_PASSWORD = 'auth/wrong-password',
}

const EmailPage = (): JSX.Element | null => {
  const { t } = useTranslation(['email', 'commonLabels', 'errorLabels']);
  const localeData = useContext(LocaleContext);
  const { rootPath } = localeData;
  const authData = useContext(AuthContext);
  const { authUser, providerId, providerIds } = authData;

  const [currentEmail, setCurrentEmail] = useState<{
    value: string;
    isFetching: boolean;
  }>({
    value: '',
    isFetching: true,
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorUpdateEmail, setErrorUpdateEmail] = useState<{
    isError: boolean;
    errorCode?: UpdateEmailErrorCodes;
  }>({
    isError: false,
  });
  const [errorSaving, setErrorSaving] = useState<boolean>(false);
  const [errorAuthenticating, setErrorAuthenticating] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [confirmationEmail, setConfirmationEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isEmailPasswordProvider, setIsEmailPasswordProvider] =
    useState<boolean>(true);
  // This is to make sure that client-side Firebase calls requiring auth do not fire during gatbsy build
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const emailInputRef = createRef();
  const confirmationEmailInputRef = createRef();
  const passwordInputRef = createRef();
  const spinningIcon = <FontAwesomeIcon icon={faSpinner} pulse />;

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (providerIds) {
      setIsEmailPasswordProvider(
        providerIds.includes(EmailAuthProvider.PROVIDER_ID),
      );
    }

    if (authUser) {
      const hasSupportedAuthProvider =
        providerIds.length > 0 &&
        providerIds.some((pId) => {
          return pId
            ? [
                EmailAuthProvider.PROVIDER_ID.toString(),
                GoogleAuthProvider.PROVIDER_ID.toString(),
                FacebookAuthProvider.PROVIDER_ID.toString(),
              ].includes(pId)
            : false;
        });

      if (!hasSupportedAuthProvider) {
        // unsupported provider type
        // redirect back to account page
        navigate(`${rootPath}account`, { replace: true });
      }

      setCurrentEmail({
        value: authUser.email || '',
        isFetching: false,
      });
    }
  }, [authData]);

  const updateUserEmail = async () => {
    setIsSaving(true);
    setErrorSaving(false);
    setErrorAuthenticating(false);
    setErrorUpdateEmail({ isError: false });

    if (authUser && email && providerId) {
      let errorAuthenticating = false;
      let provider;

      if (isEmailPasswordProvider) {
        const credential = EmailAuthProvider.credential(
          authUser.email || '',
          password,
        );
        // Now you can use that to reauthenticate
        try {
          await reauthenticateWithCredential(authUser, credential);
        } catch (error) {
          // Could not reauthenticate the user with the password
          errorAuthenticating = true;
          console.error(error);
        }
      } else {
        if (providerId === GoogleAuthProvider.PROVIDER_ID) {
          provider = new GoogleAuthProvider();
        } else if (providerId === FacebookAuthProvider.PROVIDER_ID) {
          provider = new FacebookAuthProvider();
        } else if (providerId === AppleProviderId) {
          provider = new OAuthProvider(AppleProviderId);
        }

        try {
          if (provider) {
            const currentUser = firebaseAuth.currentUser;
            if (currentUser != null) {
              await reauthenticateWithPopup(currentUser, provider);
            }
          } else {
            throw new Error('No AuthProvider was provided to reauthenticate');
          }
        } catch (error: any) {
          // could not authenticate
          errorAuthenticating = true;
          throw new Error(error);
        }
      }

      if (errorAuthenticating) {
        setErrorAuthenticating(true);
        setErrorSaving(true);
        setIsSaving(false);
        return;
      }

      // If there were no errrors re-authenticating the user account then update the email address
      // then redirect back to the account page
      try {
        await updateEmail(authUser, email);
        await sendEmailVerification(authUser);
      } catch (error: any) {
        switch (error.code) {
          case UpdateEmailErrorCodes.INVALID_EMAIL:
            setErrorUpdateEmail({
              isError: true,
              errorCode: UpdateEmailErrorCodes.INVALID_EMAIL,
            });
            break;
          case UpdateEmailErrorCodes.EMAIL_ALREADY_IN_USE:
            setErrorUpdateEmail({
              isError: true,
              errorCode: UpdateEmailErrorCodes.EMAIL_ALREADY_IN_USE,
            });
            break;
          case UpdateEmailErrorCodes.REQUIRES_RECENT_LOGIN:
          default:
            break;
        }
        setErrorSaving(true);
        setIsSaving(false);
        return;
      }

      await fetchAndUpdateUser(authUser);
      navigate(`${rootPath}account`);
    } else {
      setErrorSaving(true);
    }

    setIsSaving(false);
  };

  if (!hasMounted) {
    return null;
  }

  return (
    <Layout
      title={`${t('title')} - CloudFit`}
      description={t('metaDescription')}
      colorTheme="light">
      <Section.Container backgroundColor="light" id="email.id">
        <BackLink to={`${rootPath}account`}>
          {t('back', { ns: 'commonLabels' })}
        </BackLink>

        <Flex
          as="form"
          variant="card"
          px={[0]}
          py={[3]}
          flexDirection="column"
          onSubmit={(event) => {
            event.preventDefault();
            updateUserEmail();
          }}>
          <Title title={t('title')} summary={t('description')} />

          <Row>
            <Label>{t('newEmailLabel')}</Label>

            <ContentContainer>
              <Input
                ref={emailInputRef}
                id="email"
                name="email"
                type="email"
                placeholder={t('newEmailPlaceholder')}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
                disabled={currentEmail.isFetching || isSaving}
                required
              />
            </ContentContainer>
          </Row>

          <Row>
            <Label>{t('confirmEmailLabel')}</Label>

            <ContentContainer>
              <Input
                ref={confirmationEmailInputRef}
                id="confirmEmail"
                name="confirmEmail"
                type="email"
                placeholder={t('confirmEmailPlaceholder')}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setConfirmationEmail(event.target.value);
                }}
                sx={{
                  borderColor: confirmationEmail === email ? '' : 'cloudFitRed',

                  ':focus': {
                    outlineColor:
                      confirmationEmail === email ? '' : 'cloudFitRed',
                  },
                }}
                disabled={currentEmail.isFetching || isSaving}
                required
              />
            </ContentContainer>
            <ErrorMessage visible={email !== confirmationEmail}>
              {t('errorEntryMismatch', { ns: 'errorLabels' })}
            </ErrorMessage>
            <ErrorMessage
              visible={
                errorUpdateEmail.isError &&
                errorUpdateEmail.errorCode &&
                errorUpdateEmail.errorCode ===
                  UpdateEmailErrorCodes.INVALID_EMAIL
              }>
              {t('errorInvalidEmail')}
            </ErrorMessage>
            <ErrorMessage
              visible={
                errorUpdateEmail.isError &&
                errorUpdateEmail.errorCode &&
                errorUpdateEmail.errorCode ===
                  UpdateEmailErrorCodes.EMAIL_ALREADY_IN_USE
              }>
              {t('errorEmailAlreadyInUse')}
            </ErrorMessage>
          </Row>

          <Box
            sx={{
              display: isEmailPasswordProvider ? 'block' : 'none',
            }}>
            <Row>
              <Label>{t('passwordLabel')}</Label>
              <ContentContainer>
                <Input
                  ref={passwordInputRef}
                  id="name"
                  name="name"
                  type="password"
                  placeholder={t('passwordPlaceholder')}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value);
                  }}
                  disabled={currentEmail.isFetching || isSaving}
                />
              </ContentContainer>

              <ErrorMessage visible={errorAuthenticating}>
                {t('errorCannotAuthenticate', { ns: 'errorLabels' })}
              </ErrorMessage>
            </Row>
          </Box>

          <Row bottom>
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              width={[1]}>
              <Button
                type="button"
                variant="secondary"
                minWidth="100px"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`${rootPath}account`);
                }}>
                {t('cancelButton', { ns: 'commonLabels' })}
              </Button>
              <Button
                type="submit"
                ml={[2]}
                disabled={
                  currentEmail.isFetching ||
                  isSaving ||
                  email !== confirmationEmail ||
                  !email ||
                  (!password && isEmailPasswordProvider)
                }
                minWidth="100px">
                {isSaving
                  ? spinningIcon
                  : t('submitButton', { ns: 'commonLabels' })}
              </Button>
            </Flex>
            <ErrorMessage visible={errorSaving} rightAlign>
              {t('errorCantSave', { ns: 'errorLabels' })}
            </ErrorMessage>
          </Row>
        </Flex>
      </Section.Container>
    </Layout>
  );
};

export default EmailPage;
